<!--
 * @Author: wxb
 * @Date: 2021-07-12 17:00:41
 * @LastEditTime: 2021-07-15 21:30:16
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\index.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='6'></headerNav>
    <div class="content">
      <div class="industry"
           @click="handleClickSubject('industry')">
        <img src="../../assets/special/industry.png" />
      </div>
      <div class="special">
        <div class="special-exhibition"
             @click="handleClickSubject('exhibition')">
          <img src="../../assets/special/exhibition.png">
        </div>
        <div class="special-park"
             @click="handleClickSubject('park')">
          <img src="../../assets/special/park.png">
        </div>
      </div>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import dialogviporlogin from '@/components/dialogviporlogin'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
export default {
  name: 'special',
  components: {
    headerNav,
    footerNav,
    dialogviporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    handleClickSubject(name) {
      if (getToken()) {
        if (this.memberType === 'tourist') {
          this.$store.dispatch('getMemberInfo').then(res => {
            this.authCheck(name)
          })
        } else {
          this.authCheck(name)
        }
      } else {
        this.authCheck(name)
      }
    },
    authCheck(name) {
      const authObj = authenticate(name, this.memberType)
      if (authObj.flag) {
        this.$router.push({
          name
        })
      } else {
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.industry {
  margin-top: 10px;
  cursor: pointer;
}

.special {
  display: flex;
  align-items: center;
  align-content: space-around;
  font-size: 30px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  &-exhibition {
    flex: 1;
    cursor: pointer;
  }

  &-park {
    flex: 1;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
